import React from "react";
import styled from "styled-components";
import { TbTruckDelivery } from "react-icons/tb";
import { MdSecurity } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";

const Services = () => {
  return (
    <Wraper>
      <div className="container">
        <div className="grid grid-three-column">
          <div className="service-1">
            <div>
              <TbTruckDelivery className="icon" />
              <h3>Super Fast and Free Delivery</h3>
            </div>
          </div>
          <div className="service-2">
            <div className="service-column">
              <div>
                <MdSecurity className="icon" />
                <h3>Non-contact shipping</h3>
              </div>
            </div>
            <div className="service-column">
              <div>
                <GiReceiveMoney className="icon" />
                <h3>Money-back guaranted</h3>
              </div>
            </div>
          </div>
          <div className="service-3">
            <div>
              <RiSecurePaymentLine className="icon" />
              <h3>Super Secure Payment System</h3>
            </div>
          </div>
        </div>
      </div>
    </Wraper>
  );
};
const Wraper = styled.section`
  padding: 9rem, 0;
  .grid {
    gap: 4.8rem;
  }
  .service-1,
  .service-2,
  .service-3 {
    width: auto;
    height: 20rem;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.bg};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-conent: center;
    border-radius: 5px;
  }
  .service-2 {
    gap: 4rem;
    background-color: transparent;
    padding: 1rem 0;
    border: none;
    box-shadow: none;
  }
  .service-3 {
    padding: 4rem 0;
  }

  .service-column {
    background-color: ${({ theme }) => theme.colors.bg};
    padding: 2.2rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }

  .icon {
    font-size: 2.3rem;
    color: #5138ee;
    background-color: #fff;
    border-radius: 50%;
    width: 4rem;
    height: 3.8rem;
    padding: 0.5rem;
  }
  h3 {
    margin-top: 1.5rem;
    font-size: 1.3rem;
  }
`;
export default Services;
