import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
const StarRating = ({ ratingValue, reviews }) => {
  console.log(ratingValue);
  if (ratingValue < 0.5) {
    return null; // Return null if ratingValue is less than 0.5
  }

  const filledStars = Math.floor(ratingValue);
  const hasHalfStar = ratingValue % 1 >= 0.5;
  let emptyStars;
  if (hasHalfStar) {
    emptyStars = Math.floor(5 - filledStars - 1);
  } else {
    emptyStars = Math.floor(5 - filledStars);
  }

  return (
    <div>
      {[...Array(filledStars)].map((_, index) => {
        return <FaStar key={index} style={{ color: "#FDCC0D" }} />;
      })}
      {hasHalfStar && <FaStarHalfAlt style={{ color: "#FDCC0D" }} />}
      {Array.from({ length: emptyStars }).map((_, index) => {
        return <AiOutlineStar key={index} />;
      })}
      <span> ({reviews} costumer reviews)</span>
    </div>
  );
};

export default StarRating;
