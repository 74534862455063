import React, { useContext } from "react";
import styled from "styled-components";
import { FilterContext } from "../Context_Ecommerce_Advance/FilterContext";
import FormatPrice from "../Helper/FormatePrice";
import { FaCheck } from "react-icons/fa";

const FilterSection = () => {
  const { all_products } = useContext(FilterContext);

  const getUniqueData = (data, attr) => {
    let newVal = data.map((li) => li[attr]);
    if (attr === "colors") {
      newVal = newVal.flat();
    }
    return (newVal = ["all", ...new Set(newVal)]);
  };

  const categoryData = getUniqueData(all_products, "category");
  const companydata = getUniqueData(all_products, "company");
  const colorData = getUniqueData(all_products, "colors");

  const {
    updateFiltervalue,
    clearFilter,
    filters: { text, category, price, minPrice, maxPrice, colors },
  } = useContext(FilterContext);

  return (
    <Wraper>
      <div className="filter-items">
        <div className="search ">
          <form>
            <input
              type="text"
              placeholder="Search"
              name="text"
              value={text}
              onChange={updateFiltervalue}
            />
          </form>
        </div>
        <div className="category">
          <h3>Category</h3>
          <div className="category-style ">
            {categoryData.map((curElem, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  name="category"
                  value={curElem}
                  className={curElem === category ? " active" : ""}
                  onClick={updateFiltervalue}
                >
                  {curElem}
                </button>
              );
            })}
          </div>
        </div>
        <div className="drop-down">
          <h3>Company</h3>
          <div className="company-style">
            <select onClick={updateFiltervalue} name="company">
              {companydata.map((i) => {
                return <option>{i}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="filter-colors colors">
          <h3>Color</h3>
          <div className="filter-color-style">
            {colorData.map((currCol, index) => {
              if (currCol === "all") {
                return (
                  <button
                    key={index}
                    type="button"
                    value={currCol}
                    name="colors"
                    className="color-all--style"
                    onClick={updateFiltervalue}
                  >
                    all
                  </button>
                );
              }

              return (
                <button
                  key={index}
                  type="button"
                  value={currCol}
                  name="colors"
                  className={
                    colors === currCol ? "btnStyle active" : "btnStyle"
                  }
                  style={{ backgroundColor: currCol }}
                  onClick={updateFiltervalue}
                >
                  {colors === currCol ? (
                    <FaCheck className="checkStyle" />
                  ) : null}
                </button>
              );
            })}
          </div>
        </div>
        <div className="filter-range">
          <h3>Price</h3>
          <p>
            <FormatPrice price={price} />
          </p>
          <input
            name="price"
            type="range"
            min={minPrice}
            max={maxPrice}
            value={price}
            onChange={updateFiltervalue}
          />
        </div>
        <div className="clear-filters" onClick={clearFilter}>
          <h3>Clear all</h3>
        </div>
      </div>
    </Wraper>
  );
};
const Wraper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 13rem;
  input {
    padding: 1rem;
  }

  .filter-items > * {
    margin-top: 3rem;
  }
  .category-style {
    display: flex;
    flex-direction: column;

    button {
      border: none;
      background-color: ${({ theme }) => theme.colors.white};
      text-transform: uppercase;
      text-align: left;
      font-size: 1.4rem;
      padding: 0.6rem;
      &:hover {
        color: ${({ theme }) => theme.colors.btn};
      }
    }
    .active {
      color: ${({ theme }) => theme.colors.btn};
    }
  }
  .company-style {
    select {
      padding: 0.3rem 1.2rem;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.text};
      text-transform: capitalize;
    }
  }
  .search {
    input {
      padding: 0.6rem 1rem;
      width: 80%;
      box-shadow: none;
    }
  }
  .filter-color-style {
    display: flex;
    justify-content: center;
  }
  .filter-range {
    input {
      width: 80%;
      box-shadow: none;
      padding: 0;
    }
    p {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
  .clear-filters {
    border: 1px #e74c3c;
    background-color: #e74c3c;
    border-radius: 2px;
    text-align: center;
    align-self: center;
    ${"" /* margin-top: 3rem; */}
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    ${"" /* background-color: #000; */}
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
  }
  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }
  .color-all--style {
    background-color: transparent;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .category-style .company-style {
    text-transform: capitalize;
  }
`;
export default FilterSection;
