export const Reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_FILTER_PRODUCT":
      let priceArr = action.payload.map((p) => p.price);

      let maxPrice = Math.max(...priceArr);
      return {
        ...state,
        filter_products: [...action.payload],
        all_products: [...action.payload],
        filters: { ...state.filters, maxPrice: maxPrice, price: maxPrice },
      };

    case "SET_GRID_VIEW":
      return { ...state, gridView: true };
    case "SET_LIST_VIEW":
      return {
        ...state,
        gridView: false,
      };
    case "SORT_VALUE":
      return {
        ...state,
        sorting_value: action.payload,
      };
    case "SORTING_DATA":
      const { sorting_value, filter_products } = state;
      let sortedData = [...filter_products];
      if (sorting_value === "a-z") {
        sortedData = sortedData.sort((a, b) => a.name.localeCompare(b.name));
      }
      if (sorting_value === "z-a") {
        sortedData = sortedData.sort((a, b) => b.name.localeCompare(a.name));
      }
      if (sorting_value === "highest") {
        sortedData = sortedData.sort((a, b) => b.price - a.price);
      }
      if (sorting_value === "lowest") {
        sortedData = sortedData.sort((a, b) => a.price - b.price);
      }
      return {
        ...state,
        filter_products: sortedData,
      };
    case "UPDATE_FILTER_VALUE":
      const { name, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };

    case "FILTERING_DATA":
      const { all_products } = state;
      const { text, price, category, company, colors } = state.filters;
      let tempFilterProduct = [...all_products];
      if (text) {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          return curElem.name.toLowerCase().includes(text);
        });
      }
      if (category !== "all") {
        tempFilterProduct = tempFilterProduct.filter(
          (curElem) => curElem.category === category
        );
        console.log(tempFilterProduct);
      }
      if (company !== "all") {
        tempFilterProduct = tempFilterProduct.filter(
          (curElem) => curElem.company === company
        );
        console.log(tempFilterProduct);
      }
      if (colors !== "all") {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          return curElem.colors.includes(colors);
        });
        console.log(colors);
      }

      if (price === 0) {
        tempFilterProduct = tempFilterProduct.filter(
          (curElem) => curElem.price === price
        );
      } else {
        tempFilterProduct = tempFilterProduct.filter(
          (curElem) => curElem.price <= price
        );
      }
      return {
        ...state,
        filter_products: tempFilterProduct,
      };
    case "CLEAR_ALL":
      return {
        ...state,
        filters: {
          ...state.filters,
          text: "",
          category: "all",
          company: "all",
          colors: "all",
          price: state.filters.maxPrice,
          // maxPrice: 0,
        },
      };

    default:
      return state;
  }
};
