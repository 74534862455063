import React from "react";
import styled from "styled-components";
import HeroSection from "../components/HeroSection";

const About = () => {
  const myData = {
    name: "About us",
  };
  return (
    <Wraper>
      <HeroSection Data={myData} />
    </Wraper>
  );
};
const Wraper = styled.section`
  height: 100vh;
`;
export default About;
