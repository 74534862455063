export const Reducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    let { id, color, quantity, prod } = action.payload;
    let cartProducts;
    cartProducts = {
      id: id + color,
      name: prod.name,
      color,
      quantity,
      image: prod.image[0].url,
      price: prod.price,
      max: prod.stock,
    };
    const existingProductIndex = state.Cart.findIndex(
      (item) => item.id === cartProducts.id
    );

    if (existingProductIndex !== -1) {
      const updatedCart = [...state.Cart];
      const existingProduct = updatedCart[existingProductIndex];
      existingProduct.quantity += quantity;
      return { ...state, Cart: updatedCart };
    }
    return { ...state, Cart: [...state.Cart, cartProducts] };
    // console.log(cartProducts);
  }
  if (action.type === "INCREASING") {
    let product = state.Cart.map((currElem) => {
      if (currElem.id === action.payload) {
        let incrementValue = currElem.quantity + 1;
        if (incrementValue >= currElem.max) {
          incrementValue = currElem.max;
        }
        return {
          ...currElem,
          quantity: incrementValue,
        };
      } else {
        return currElem;
      }
    });
    return {
      ...state,
      Cart: product,
    };
  }
  if (action.type === "DECREASING") {
    let product = state.Cart.map((currElem) => {
      if (currElem.id === action.payload) {
        let decrementValue = currElem.quantity - 1;
        if (decrementValue <= 1) {
          decrementValue = 1;
        }
        return {
          ...currElem,
          quantity: decrementValue,
        };
      } else {
        return currElem;
      }
    });
    return {
      ...state,
      Cart: product,
    };
  }
  if (action.type === "REMOVE_ITEM") {
    let removed = state.Cart.filter((currElem) => {
      return currElem.id !== action.payload;
    });
    return {
      ...state,
      Cart: removed,
    };
  }
  if (action.type === "CLEAR_CART") {
    return {
      ...state,
      Cart: [],
    };
  }
  // ======SEPERATELY ======

  // if (action.type === "CART_TOTAL_ITEM") {
  //   let updatedItemVal = state.cart.reduce((initialVal, curElem) => {
  //     let { amount } = curElem;

  //     initialVal = initialVal + amount;
  //     return initialVal;
  //   }, 0);

  //   return {
  //     ...state,
  //     total_item: updatedItemVal,
  //   };
  // }

  // if (action.type === "CART_TOTAL_PRICE") {
  //   let total_price = state.cart.reduce((initialVal, curElem) => {
  //     let { price, amount } = curElem;

  //     initialVal = initialVal + price * amount;

  //     return initialVal;
  //   }, 0);

  //   return {
  //     ...state,
  //     total_price,
  //   };
  // }

  // =========COMBINED=========

  if (action.type === "CART_QTY_CUM_PRICE") {
    // if (state.Cart.length === 0) {
    //   TotalQty = 0;
    // } else {
    let { CartTotal, totalPrice } = state.Cart.reduce(
      (acc, currVal) => {
        let { price, quantity } = currVal;
        acc.CartTotal += quantity;
        acc.totalPrice += price * quantity;
        return acc;
      },
      { CartTotal: 0, totalPrice: 0 }
    );
    // }
    return {
      ...state,
      CartTotal,
      totalPrice,
    };
  }
};
