import React, { useContext } from "react";

import { FilterContext } from "../Context_Ecommerce_Advance/FilterContext";
import GridView from "./GridView";
import ListView from "./ListView";
const ProductList = () => {
  const { filter_products, gridView } = useContext(FilterContext);
  if (gridView === true) {
    return <GridView products={filter_products} />;
  }
  if (gridView === false) {
    return <ListView products={filter_products} />;
  }
};

export default ProductList;
