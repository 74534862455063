import React, { useEffect, useState } from "react";
import { NavItems } from "./Maping-Items";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FiShoppingCart } from "react-icons/fi";
import { CgMenu, CgClose } from "react-icons/cg";
import { CartConsume } from "../Context_Ecommerce_Advance/CartContext";
const Nav = () => {
  const { Cart, cartQtyCumPrice, CartTotal } = CartConsume();

  const [menu, setMenu] = useState();
  useEffect(() => {
    cartQtyCumPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cart]);

  return (
    <Navr>
      <div className={menu ? "navbar active" : "navbar"}>
        <ul className="navbar-lists">
          {NavItems.map((i, index) => (
            <li key={index}>
              <NavLink
                to={i.to}
                className={i.class}
                onClick={() => setMenu(false)}
              >
                {i.name}
              </NavLink>
            </li>
          ))}
          <li>
            <NavLink
              to="/cart"
              className="navbar-link cart-trolley--link"
              onClick={() => setMenu(false)}
            >
              <FiShoppingCart className="cart-trolley" />
              <span className="cart-total--item">{CartTotal}</span>
            </NavLink>
          </li>
        </ul>
        <div className="mobile-navbar--btn">
          <CgMenu
            name="menu-outline"
            className="mobile-nav--icon "
            onClick={() => setMenu(true)}
          />
          <CgClose
            name="close-outline"
            className="mobile-nav--icon close-outline"
            onClick={() => setMenu(false)}
          />
        </div>
      </div>
    </Navr>
  );
};
const Navr = styled.nav`
  .navbar {
    padding: 1.5rem;
  }
  .navbar-lists {
    display: flex;
    align-items: center;
    gap: 3.8rem;
    list-style-type: none;
  }

  .navbar-link {
    &:link,
    &:visited {
      display: inline-block;
      text-decoration: none;
      font-size: 1.6rem;
      font-weight: 400;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.black};
      transition: color 1s linear;
    }
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.helper};
    }
  }
  .cart-trolley--link {
    position: relative;
    .cart-trolley {
      position: relative;
      font-size: 3rem;
    }
    .cart-total--item {
      position: absolute;
      background-color: ${({ theme }) => theme.colors.helper};
      border-radius: 50%;
      font-size: 1rem;
      display: grid;
      place-items: center;
      width: 1.6rem;
      height: 1.6rem;
      top: -10%;
      left: 60%;
      color: ${({ theme }) => theme.colors.text};
      font-weight: 600;
    }
  }
  .mobile-navbar--btn {
    display: none;
  }
  .mobile-nav-icon[name="close-outline"] {
    display: none;
  }

  .close-outline {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .mobile-navbar--btn {
      display: inline-block;
      z-index: 9999;
    }
    .navbar-lists {
      display: none;
    }
    .mobile-nav--icon {
      font-size: 2rem;
      position: absolute;
      top: 35%;
      right: -50%;
      z-index: 9999;
    }
    .active .mobile-nav--icon {
      display: none;
      font-size: 2.3rem;
      position: absolute;
      top: 35%;
      right: -50%;
      z-index: 9999;
    }

    .active .close-outline {
      display: inline-block;
    }

    .navbar-lists {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      right: -133%;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      visibility: hidden;
      opacity: 0;
      transform: translateX(-100%);
      /*transform-origin: top;*/
    }

    .active .navbar-lists {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      z-index: 999;
      transform-origin: right;
      transition: all 0.9s linear;
      .navbar-link {
        font-size: 1.2rem;
      }
    }
  }
`;

export default Nav;
