import React, { useState } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import CartQantityToggle from "./CartQantityToggle";
import { NavLink } from "react-router-dom";
import { Button } from "./Button";
import { CartConsume } from "../Context_Ecommerce_Advance/CartContext";

const AddToCart = ({ prod }) => {
  const { addToCart } = CartConsume();

  const { colors, stock, id } = prod;
  const [color, setColor] = useState(colors[0]);

  const [quantity, setQuantity] = useState(1);
  const increasing = () => {
    quantity < stock ? setQuantity(quantity + 1) : setQuantity(stock);
  };
  const decreasing = () => {
    quantity > 1 ? setQuantity(quantity - 1) : setQuantity(1);
  };
  console.log(quantity);
  console.log(stock);

  return (
    <Wraper>
      <div className="colors">
        <p>
          Color
          {colors.map((currColor, index) => {
            return (
              <button
                key={index}
                style={{ backgroundColor: currColor }}
                onClick={() => setColor(currColor)}
                className={color === currColor ? "btnStyle active" : "btnStyle"}
              >
                {color === currColor ? (
                  <FaCheck className="checkStyle" />
                ) : null}
              </button>
            );
          })}
          ;
        </p>
      </div>
      <CartQantityToggle
        quantity={quantity}
        decreasing={decreasing}
        increasing={increasing}
      />
      <NavLink to="/cart" onClick={() => addToCart(prod, id, color, quantity)}>
        <Button className="btn">Add To Cart</Button>
      </NavLink>
    </Wraper>
  );
};
const Wraper = styled.section`
  .colors p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .btnStyle {
    width: 1.8rem;
    height: 1.8rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
  }
  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }
  .amount-toggle {
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.4rem;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }
`;
export default AddToCart;
