import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
const CartQantityToggle = ({ quantity, increasing, decreasing }) => {
  return (
    <div className="cart-button">
      <div className="amount-toggle">
        <button onClick={() => decreasing()}>
          <FaMinus />
        </button>
        <div className="amount-style"> {quantity}</div>
        <button onClick={() => increasing()}>
          <FaPlus />
        </button>
      </div>
    </div>
  );
};

export default CartQantityToggle;
