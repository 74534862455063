import React from "react";
import styled from "styled-components";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { Button } from "react-bootstrap";
const Footer = () => {
  return (
    <Wraper>
      <div className="contact-section">
        <div className="grid grid-two-column">
          <div>
            <h3>Ready to get started?</h3>
            <h3>Talk to us today</h3>
          </div>
          <div>
            <Button>Get started</Button>
          </div>
        </div>
      </div>
      <div className="Footer-section">
        <div className="container">
          <div className="grid grid-four-column">
            <div className="footer-items">
              <h3>Rj_Tech</h3>
              <p>a group of sentences or a single sentence that forms a unit</p>
            </div>
            <div className="footer-items">
              <h3>Subscribe to get important updates</h3>
              <div className="input">
                <input placeholder="your email" />
              </div>
              <div className="btn">
                <Button>Subscribe</Button>
              </div>
            </div>
            <div className="footer-items">
              <h3>Follow us</h3>
              <div className="icons">
                <div>
                  <FaInstagram className="icon" />
                </div>
                <div>
                  <FaYoutube className="icon" />
                </div>
                <div>
                  <FaFacebook className="icon" />
                </div>
              </div>
            </div>
            <div className="footer-items">
              <h3>Call us</h3>
              <p>+919376220440</p>
            </div>
          </div>
          <div className="footer-buttom">
            <hr />
            <div className="grid grid-two-column">
              <p>@{new Date().getFullYear()} Rj_Tech. All Rights Reserved</p>
              <div>
                <p>PRIVACY POLICY</p>
                <p>TERMS & CONDITIONS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wraper>
  );
};
const Wraper = styled.section`
  padding: 1rem 0 0 0;
  .grid {
    gap: 4rem;
  }
  .footer-items {
    background-color: transparent;
    min-width: 19rem;

    text-align: left;
    padding: 6rem 2rem;
    h3 {
      font-size: 1.4rem;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.white};
    }
    p {
      font-size: 1.2rem;
      margin-top: 2rem;
      color: ${({ theme }) => theme.colors.white};
    }
    .input {
      line-height: 0.3rem;
      margin-top: 2rem;
      input {
        height: 0.1rem;
        width: 14rem;
      }
    }
    .icons {
      display: flex;

      justify-content: space-between;
      margin-top: 1.8rem;
      padding: 2rem 0;

      .icon {
        font-size: 3rem;
        border: 2px solid ${({ theme }) => theme.colors.bg};
        border-radius: 50%;
        padding: 0.3rem;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
  .contact-section {
    padding: 0.8rem 9rem;
    background-color: ${({ theme }) => theme.colors.bg};
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    border-radius: 1rem;
    max-width: 70vw;
    margin-left: 15%;
    transform: translateY(50%);
    h3 {
      font-size: 1.5rem;
    }
    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }
  }

  .Footer-section {
    background-color: ${({ theme }) => theme.colors.footer_bg};
  }
  .footer-buttom {
    hr {
      margin-bottom: 1rem;
      background: ${({ theme }) => theme.colors.white};
      height: 0.1px;
    }
  }
  p {
    font-size: 1.2rem;
    margin-top: 2rem;
    color: ${({ theme }) => theme.colors.white};
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .contact-section {
      .grid div:last-child {
        justify-self: center;
      }
    }
    .grid-four-column div:nth-child(n + 2) {
      padding: 0;
    }
    .grid-four-column > div:nth-child(1) {
      padding: 10rem 2rem 1rem 0;
    }
    .footer-items {
      .icons {
        justify-content: flex-start;
        .icon {
          margin-right: 1.2rem;
        }
      }
    }
  }
`;

export default Footer;
