import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import { ProdReducer } from "../Reducer_Eccom_Advance/ProductReducer";

const AppContext = createContext();
const Api = "https://api.pujakaitem.com/api/products";
const AppProvider = ({ children }) => {
  const initialState = {
    isLoading: false,
    error: false,
    productData: [],
    featuredProduct: [],
    isSingleLoading: false,
    singleProduct: {},
    singleError: false,
  };

  const [state, dispatch] = useReducer(ProdReducer, initialState);
  const getProducts = async (url) => {
    dispatch({ type: "LOADING_API" });
    try {
      await axios.get(url).then((res) => {
        const products = res.data;
        console.log(products);
        dispatch({ type: "MY_API_DATA", payload: products });
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "ERROR_API" });
    }
  };
  const getSingleProducts = async (singleUrl) => {
    dispatch({ type: "LOADING_SINGLE" });
    try {
      await axios.get(singleUrl).then((res) => {
        const singleProduct = res.data;
        console.log(singleProduct);
        dispatch({ type: "MY_API_SINGLE_DATA", payload: singleProduct });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts(Api);
  }, []);

  return (
    <AppContext.Provider value={{ ...state, getSingleProducts }}>
      {children}
    </AppContext.Provider>
  );
};
const ProductContext = () => {
  return useContext(AppContext);
};
export { AppContext, AppProvider, ProductContext };
