export const ProdReducer = (state, action) => {
  switch (action.type) {
    case "LOADING_API":
      return { ...state, isLoading: true };
    case "ERROR_API":
      return { ...state, isLoading: false, error: true };
    case "MY_API_DATA":
      const featuredProduct = action.payload.filter((a) => {
        return a.featured === true;
      });

      return {
        ...state,
        featuredProduct: featuredProduct,
        productData: action.payload,
        isLoading: false,
        error: false,
      };
    case "MY_API_SINGLE_DATA":
      return {
        ...state,
        singleProduct: action.payload,
        isSingleLoading: false,
      };
    case "LOADING_SINGLE":
      return { ...state, isSingleLoading: true };
    default:
      return state;
  }
};
