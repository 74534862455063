import styled from "styled-components";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProductContext } from "../Context_Ecommerce_Advance/ProductsContext";
import FormatePrice from "../Helper/FormatePrice";
import { TbTruckDelivery } from "react-icons/tb";
import { MdSecurity } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";
import FormatPrice from "../Helper/FormatePrice";
import MyImage from "../components/MyImage";
import PageNavigation from "../components/PageNavigation";
import StarRating from "../components/StarRating";
import AddToCart from "../components/AddToCart";
export const SingleProduct = () => {
  const { id } = useParams();
  const { getSingleProducts, isSingleLoading, singleProduct } =
    ProductContext();
  const API = "https://api.pujakaitem.com/api/products";

  useEffect(() => {
    getSingleProducts(`${API}?id=${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    id: alias,
    name,
    stock,
    stars,
    description,
    price,
    reviews,
    company,
    image,
    colors,
  } = singleProduct;
  console.log(singleProduct);
  console.log(colors);
  if (isSingleLoading) {
    return (
      <div>
        <h3>Loading...</h3>
      </div>
    );
  }
  return (
    <Wrapper>
      <PageNavigation title={name} />
      <div className="container">
        <div className="grid grid-two-column">
          <div className="product-images">
            <MyImage imgs={image} />
          </div>
          <div className="product-data">
            <h2>{name}</h2>

            <p>
              {stars && <StarRating ratingValue={stars} reviews={reviews} />}
            </p>

            <p className=" product-data-price">
              MRP :
              <del>
                <FormatePrice price={price + 100000} />
              </del>
            </p>
            <p className="product-data-price product-data-real-price">
              Deal of the day <FormatPrice price={price} />
            </p>
            <p className="descrip">{description}</p>
            <div className="product-data-warranty">
              <div className="product-warranty-data">
                <TbTruckDelivery className="warranty-icon" />
                <p>gfgf</p>
              </div>
              <div className="product-warranty-data">
                <GiReceiveMoney className="warranty-icon" />
                <p> vfgf</p>
              </div>
              <div className="product-warranty-data">
                <MdSecurity className="warranty-icon" />
                <p> vvc</p>
              </div>
              <div className="product-warranty-data">
                <RiSecurePaymentLine className="warranty-icon" />
                <p>ghdfd</p>
              </div>
            </div>
            <div className="product-data-info">
              <p>
                Available:
                <span>{stock > 0 ? "In stock" : "Out of stock"}</span>
              </p>
              <p>
                ID: <span>{alias}</span>
              </p>
              <p>
                BRAND: <span>{company}</span>
              </p>
            </div>
            <hr />
            {stock > 0 && <AddToCart prod={singleProduct} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .container {
    padding: 5rem 0;
  }
  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.9rem;

    .descrip {
      font-size: 1.3rem;
    }

    h2 {
      font-size: 3.5rem;
    }
    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;

      .product-warranty-data {
        text-align: center;

        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.1rem;
          padding-top: 0.4rem;
        }
      }
    }

    .product-data-price {
      font-weight: bold;
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        font-size: 1.3rem;
      }

      span {
        font-weight: bold;
      }
    }

    hr {
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid #000;
      color: red;
    }
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;
  }
`;

export default SingleProduct;
