import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
const PageNavigation = ({ title }) => {
  return (
    <Wraper>
      <NavLink to="/">Home /</NavLink>&nbsp; {title}
    </Wraper>
  );
};
const Wraper = styled.section`
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.bg};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
`;
export default PageNavigation;
