const NavItems = [
  {
    name: "Home",
    to: "/",
    class: "navbar-link",
  },
  {
    name: "About",
    to: "/about",
    class: "navbar-link",
  },
  {
    name: "Products",
    to: "/products",
    class: "navbar-link",
  },
  {
    name: "Contact",
    to: "/contact",
    class: "navbar-link",
  },
];

const ImageSrc = [
  "https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image2.png",
  "https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image3.png",
  "https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image4.png",
  "https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image6.png",
  "https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image8.png",
];

export { NavItems, ImageSrc };
