import React from "react";
import styled from "styled-components";
import HeroSection from "../components/HeroSection";
import Services from "../components/Services";
import Trusted from "../components/Trusted";

import FeaturedProduct from "../components/FeaturedProduct";

const Home = () => {
  const myData = {
    name: "Welcome to",
  };
  return (
    <Wraper>
      <HeroSection Data={myData} />
      <FeaturedProduct />
      <Services />
      <Trusted />
    </Wraper>
  );
};
const Wraper = styled.section``;
export default Home;
