import React, { useEffect, useReducer } from "react";
import { createContext, useContext } from "react";
import { Reducer } from "../Reducer_Eccom_Advance/Cartreducer";

const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const getCartData = () => {
    let CartData = localStorage.getItem("RjCart");
    if (CartData === []) {
      return [];
    } else {
      return JSON.parse(CartData);
    }
  };
  const initialstate = {
    Cart: [],
    // Cart: getCartData(),
    CartTotal: "",
    totalPrice: "",
    shipping_fee: 5000,
  };

  const [state, dispatch] = useReducer(Reducer, initialstate);
  const addToCart = (prod, id, color, quantity) => {
    dispatch({ type: "ADD_TO_CART", payload: { id, color, prod, quantity } });
  };
  const increasing = (id) => {
    dispatch({ type: "INCREASING", payload: id });
  };
  const decreasing = (id) => {
    dispatch({ type: "DECREASING", payload: id });
  };
  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };
  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };
  const cartQtyCumPrice = () => {
    dispatch({ type: "CART_QTY_CUM_PRICE" });
  };
  useEffect(() => {
    localStorage.setItem("RjCart", JSON.stringify(state.Cart));
  }, [state.Cart]);
  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        increasing,
        decreasing,
        removeItem,
        clearCart,
        cartQtyCumPrice,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
export const CartConsume = () => {
  return useContext(CartContext);
};
