import React from "react";

const Error = () => {
  return (
    <div>
      <h2>404</h2>
      <h2>Uh! Oh! </h2>
      <p> The page you are looking for does not exist.</p>
    </div>
  );
};

export default Error;
