import React from "react";
import { NavLink } from "react-router-dom";
import FormatePrice from "../Helper/FormatePrice";
import styled from "styled-components";
const Product = (items) => {
  const { price } = items;
  return (
    <Wraper>
      <NavLink to={`/singleproduct/${items.id}`}>
        <div className="card">
          <figure className="img">
            <img src={items.image} alt={items.name} height="160" />
            <figcaption className="caption">{items.category}</figcaption>
          </figure>
          <div className="card-data">
            <div className="card-flex">
              <h3>{items.name}</h3>
              <p className="price">{<FormatePrice price={price} />}</p>
            </div>
          </div>
        </div>
      </NavLink>
    </Wraper>
  );
};
const Wraper = styled.section`
  .card {
    background-color: #fff;
    border-radius: 1rem;
  }
  img {
    max-width: 90%;
    ${"" /* height: 20rem; */}
    width:auto;
    margin: 1.4rem;
    border-radius: 1rem;
  }
  figure {
    overflow: hidden;
    width: auto;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 82%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.5);
    }
    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }
  h3 {
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
  }

  .price {
    color: ${({ theme }) => theme.colors.helper};
  }
  .card-data {
    padding: 0 2rem;
  }
  .card-flex {
    display: flex;
    justify-content: space-between;
    width: 20rem;
  }
`;
export default Product;
