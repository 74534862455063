import React, { useEffect, useReducer } from "react";
import { ProductContext } from "./ProductsContext";
import { createContext } from "react";
import { Reducer } from "../Reducer_Eccom_Advance/FilterReducer";
const FilterContext = createContext();
export const FilterContextProvider = ({ children }) => {
  const { productData } = ProductContext();
  // const { price } = productData;

  const initialState = {
    filter_products: [],
    all_products: [],
    gridView: true,
    sorting_value: "lowest",
    filters: {
      text: "",
      category: "all",
      company: "all",
      colors: "all",
      price: 0,
      maxPrice: 0,
      minPrice: 0,
    },
  };

  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    dispatch({ type: "LOAD_FILTER_PRODUCT", payload: productData });
  }, [productData]);
  useEffect(() => {
    dispatch({ type: "FILTERING_DATA" });
    dispatch({ type: "SORTING_DATA" });
  }, [productData, state.sorting_value, state.filters]);

  const setGridView = () => {
    return dispatch({ type: "SET_GRID_VIEW" });
  };

  const setListView = () => {
    dispatch({ type: "SET_LIST_VIEW" });
  };
  const sorting = (e) => {
    let userValue = e.target.value;
    dispatch({ type: "SORT_VALUE", payload: userValue });
  };
  const clearFilter = () => {
    dispatch({ type: "CLEAR_ALL" });
  };

  const updateFiltervalue = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    dispatch({ type: "UPDATE_FILTER_VALUE", payload: { value, name } });
  };
  return (
    <FilterContext.Provider
      value={{
        ...state,
        setGridView,
        setListView,
        sorting,
        updateFiltervalue,
        clearFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
export { FilterContext };
