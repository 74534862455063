import React, { useState } from "react";
import styled from "styled-components";
const MyImage = ({ imgs = [{ url: "" }] }) => {
  const [mainImage, setMainImage] = useState(imgs[0]);
  return (
    <Wraper>
      <div className="image">
        <div className="thumbnail-img grid grid-four-rows">
          {imgs.map((img) => {
            return (
              <img
                src={img.url}
                alt={img.filename}
                onClick={() => setMainImage(img)}
              />
            );
          })}
        </div>
        <div className="main-image">
          <img src={mainImage.url} alt="" />
        </div>
      </div>
    </Wraper>
  );
};
const Wraper = styled.section`
  .grid {
    gap: 1rem;
    img {
      height: 5rem;
      width: 7.5rem;
    }
  }
  .main-image {
    img {
      height: 18rem;
      width: 27rem;
    }
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`;
export default MyImage;
