import styled from "styled-components";
export const Button = styled.button`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.btn};
  max-width: auto;
  color: rgb(255 255 255);
  padding: 1rem 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  &:hover,
  &:active {
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }
`;
