import React from "react";
import styled from "styled-components";
import { ImageSrc } from "../components/Maping-Items";
const Trusted = () => {
  return (
    <Wraper>
      <div className="container">
        <h3>Trusted By 1000+ Companies</h3>
        <div className="brand-section-slider">
          {ImageSrc.map((i) => (
            <div className="slide">
              <img src={i} alt="trusted-brands" className="img" />
            </div>
          ))}
        </div>
      </div>
    </Wraper>
  );
};
const Wraper = styled.section`
  padding: 9rem 0;
  .container {
    max-width: 100%;
    background-color: ${({ theme }) => theme.colors.bg};
  }
  .brand-section-slider {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slide {
    .img {
      width: 17rem;
      height: 13rem;
      padding: 3rem;
    }
  }
  h3 {
    text-align: center;
    padding-top: 3rem;
    font-size: 2rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .brand-section-slider {
      flex-wrap: wrap;
    }
  }
`;

export default Trusted;
