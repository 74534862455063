import React from "react";
import { ProductContext } from "../Context_Ecommerce_Advance/ProductsContext";
import Product from "./Product";
import styled from "styled-components";
const FeaturedProduct = () => {
  const { isLoading, featuredProduct } = ProductContext();

  if (isLoading) {
    return (
      <div>
        <h3>...Loading</h3>
      </div>
    );
  }
  return (
    <Wraper>
      <div className="section">
        <div className="container">
          <div className="intro-data"> check now!</div>
          <div className="common-heading">Our featured Services</div>
          <div className="grid grid-three-column">
            {featuredProduct.map((items) => {
              return <Product key={items.id} {...items} />;
            })}
          </div>
        </div>
      </div>
    </Wraper>
  );
};
const Wraper = styled.section`
  padding: 9rem 0;
  .section {
    background-color: ${({ theme }) => theme.colors.bg};
  }
  .container {
    max-width: 90rem;
    padding: 3rem 0;
  }
  .grid {
    gap: 4.8rem;
  }
  .common-heading {
    font-size: 3rem;
  }
  /* === apply this if you don't want to slide the card =====*/

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .container {
      max-width: 30rem;
    }
  }
`;
export default FeaturedProduct;
